import React, { Component } from 'react'
import '../css/Pic.css'

class Pic extends Component {
    render() {
      return (
        <div className="Pic">
            <img src={this.props.image}/>
            <p className="description">{this.props.caption}</p>
        </div>   
      )
    }
  }
  
  export default Pic;


  