import React, { Component } from 'react'
import { Link } from "gatsby"
import Header from "../components/Header"
import ProjectHeader from "../components/ProjectHeader"
import Footer from "../components/Footer"
import '../css/project.css'
import Fade from 'react-reveal/Fade';
import Pic from "../components/Pic"
import SmallPic from "../components/SmallPic"


class Project extends Component {
  render() {
    return (
      <div> 
    <Header />
    <Fade>
    <div className="ProjectContainer">
      <ProjectHeader
        title="Berkeleytime"
        description="Product Lead"
        timeline="2017 — 2020"
        image={require('../images/bt2.png')}
      />
      <h6>Background</h6>
      <h2><a href="https://www.berkeleytime.com/landing">Berkeleytime</a> is a student-founded course discovery web-app acquired by, and now operating under, the <a href ="https://octo.asuc.org/">ASUC Office of the CTO</a>. </h2>
      <p>
        In 2017, I joined Berkeleytime as the team's first designer. I was tasked with leading a huge overhaul of the site. Over the course of two years, I worked hand-in-hand with PM and engineering to reimagine the site and build up its design system. During my time on the BT team, I brought design to the forefront of product and team decisions. On the product  side, I created the Berkeleytime design system from scratch in Figma, led the redesign of our core web and mobile experiences, and created the MVP for our course scheduler. On the team side, I initiated our first-ever design recruitment schedule, growing the design team from one to three, and led our first user research and user testing sessions.
      </p>
      <Pic
        image={require('../images/bt-old.png')}
        caption="Old site"
      />  
      <Pic
        image={require('../images/bt-new.png')}
        caption="The current redesigned site"
      />  
      <p>
        In addition to the design overhaul, our engineering team was radically changing the codebase. Our frontend team worked extensively to rewrite the site in React/Redux. Towards the launch of our second major redesign, I contributed directly to the codebase, learning React in order to work in parallel with the frontend team as we built out a new design system into code. 
      </p>
      <SmallPic
        image={require('../images/commit.png')}
        caption="I learned React and SCSS, working closely with the frontend team to ensure components and styles would be reusable in the future."
      />  
      <Link className="BackHome" to="/">Back to work</Link>
    </div>
    <Footer />
    </Fade>
  </div>
    )
  }
}

export default Project;